import config from '../config';

import { getStorageItem } from 'services/applicationStorage';

export const baseUri = () => {
    const env = getStorageItem('csm-env', true)?.value;
    if (env === 'Sandbox') { return config.server.sandbox.baseUri }
    if (env === 'Development') { return config.server.dev.baseUri }
    if (env === 'Test') { return config.server.test.baseUri }
    if (env === 'Production') { return config.server.prod.baseUri }
    return '';
};

export const fetchWrap = async (url, options, token) => {

    const standardResponse = {
        success: false,
        status: 0,
        data: {},
        url: ''
    };

    const response = await fetch(url, {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    // need to no-content returns
    if (response.status === 204) {
        standardResponse.data = {};
    } else {
        standardResponse.data = await response.json();
    }

    standardResponse.status = response.status;
    standardResponse.url = response.url;
    if (!response.ok) { return Promise.reject(standardResponse); };
    standardResponse.success = true;
    return standardResponse;
};

export const fetchWithRetries = async (url, options, token, retries = process.env.REACT_APP_FETCH_RETRIES_COUNT | 3) => {
    const retryErrors = [];
    let responseErrors = [];

    while (retries != 0) {
        retries = retries - 1;

        const envCheck = process.env.REACT_APP_CUSTOM_ENV.trim() == 'local';

        if (process.env.REACT_APP_FETCH_CALL_VERBOSE | envCheck) {
            console.log(`Attempting to URL ${options.method} Request: ${url}. Request Options: ${JSON.stringify(options)}  Retries Left: ${retries}`);
        }
        
        try {
            return await fetchWrap(url, options, token);
        } 
        catch ({ data, success, status, url }) {
            retryErrors.push(`Attempt: ${3 - retries} Success: ${success} | Response Error: ${JSON.stringify(data)} | HTTP Status: ${status}`);
            responseErrors = [...responseErrors, { data, success, status, url}];
        }
    }

    console.log(`Error Retries: ${JSON.stringify(retryErrors)}`);
    throw responseErrors[responseErrors.length - 1];
};

export const fetchAllWrap = async (promises) => {
    const response = await Promise.allSettled(promises);
    return response;
};

export const fetchAllWithRetries = async (promises, retries = process.env.REACT_APP_FETCH_RETRIES_COUNT | 3) => {
    const retryErrors = [];
    let responseErrors = [];

    while (retries != 0) {
        retries = retries - 1;

        const envCheck = process.env.REACT_APP_CUSTOM_ENV.trim() == 'local';

        if (process.env.REACT_APP_FETCH_CALL_VERBOSE | envCheck) {
            console.log(`Attempting to Fetch ALL | Promises Length: ${promises.length} Retries Left: ${retries}`);
        }

        try {
            return await fetchAllWrap(promises);
        }
        catch ({ data, success, status, url }) {
            retryErrors.push(`Attempt: ${3 - retries} Success: ${false} | Response Error: ${JSON.stringify(data)}`);
            responseErrors = [...responseErrors, { data, success, status, url }];
        }
    }

    console.log(`Error Retries: ${JSON.stringify(retryErrors)}`);
    throw responseErrors[responseErrors.length - 1];
}

export default {
    fetchWrap,
    fetchWithRetries,
    fetchAllWrap,
    fetchAllWithRetries,
    baseUri
}