
import { fetchWithRetries, baseUri } from './fetchWrap';

/**
 * @description Adds a new organization to a customer (dependency)
 * @param {*} fields organizationName, organizationId, country, region, platform, contactNames, contactEmails
 * @param {*} customer customerId
 * @param {*} token okta
 * @return {*} new organization id
 */
export const addOrganization = (fields, customer, token) => {
    const url = `${baseUri()}/customers/${customer}/organizations`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

/**
 * @description Updates the organization with the provided values.
 * @param {*} id organizationId
 * @param {*} fields organizationName, country, region, platform, contactNames, contactEmails, active, archived
 * @param {*} customer customerId
 * @param {*} token okta
 * @return {*} updated org details
 */
export const updateOrganization = (id, fields, customer, token) => {
    const url = `${baseUri()}/customers/${customer}/organizations/${id}`;
    return fetchWithRetries(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

/**
 * @description Deletes the organization with the provided values.
 * @param {*} id organizationId
 * @param {*} customer customerId
 * @param {*} token okta
 * @return {*} no content
 */
export const deleteOrganization = (id, customer, token) => {
    const url = `${baseUri()}/customers/${customer}/organizations/${id}`;
    return fetchWithRetries(url, {
        method: 'DELETE'
    }, token);
};


/**
 * @description Retrieves a potential deployment based on the provided uuid.
 * @param {*} id organizationId (UUID)
 * @param {*} token okta
 * @return {*} deployment
 */
export const getOrganization = (id, token, customer = '') => {
    const url = `${baseUri()}/customers/${customer}/organizations/${id}`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token);
}

export default {
    addOrganization,
    updateOrganization,
    deleteOrganization,
    getOrganization
}
