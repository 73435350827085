import { useOktaAuth } from '@okta/okta-react';
import React, { Fragment, useContext } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

// Components Material UI
import {
  Typography, Divider, Box,
  Grid, Card, CardMedia, CardContent,
  CardActions, Button, LinearProgress
} from '@mui/material';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

// Components
import SearchDeployment from 'components/shared/SearchDeployment';

// Utilities

// Context
import PermissionsContext from 'context/PermissionsContext';
import { isAuthorizedByFunction } from 'components/shared/utilities';

const Home = ({ userInfo }) => {
  const history = useHistory();
  const { authState } = useOktaAuth();
  // Context
  const { me, contextLoaded, noUser } = useContext(PermissionsContext);

  const navigateToCustomers = () => {
    history.push('/customers');
  };

  const navigateToProducts = () => {
    history.push('/solutions');
  };

  if (!authState || !contextLoaded) {
    return (
      <Box sx={{ my: 1, mx: 1 }}>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  if (contextLoaded && noUser) {
    return <Redirect to={'/unauthorized'}/>
  }

  return (
    <div id="home">
      <div>
        {authState.isAuthenticated && userInfo && userInfo.name === undefined && (
          <Box sx={{ my: 1, mx: 1 }}>
            <LinearProgress color="secondary" />
          </Box>
        )}

        {authState.isAuthenticated && userInfo && userInfo.name !== undefined
          && (
            <Fragment>
              <Box sx={{ my: 1, mx: 1 }}>
                <Grid container direction='row' justifyContent="space-between" alignItems="center">
                  <Grid item xs>
                    <Typography variant="h4" component="div">
                      Welcome,&nbsp;{userInfo.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography color="text.secondary" variant="body2">
                  This app manages customers, customer deployments, customer subscriptions, solutions, and solution licenses.
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Grid container justifyItems={'center'} alignItems={'center'} sx={{ mt: 1 }} spacing={2}>
                    <Grid item xs={6}>
                      <Card>
                        <CardMedia
                          component="img"
                          alt="customer images"
                          height="220"
                          image="/resources/customers.webp"
                        />
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Customers
                          </Typography>
                          <Typography variant="h5" component="div">
                            Manage Customers
                          </Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            customer details, deployments, subscriptions
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button onClick={navigateToCustomers}>Manage &nbsp; <ManageAccountsIcon /></Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardMedia
                          component="img"
                          alt="product images"
                          height="220"
                          image="/resources/products.jpg"
                        />
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Solutions
                          </Typography>
                          <Typography variant="h5" component="div">
                            Manage Solutions
                          </Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            solution details, solution properties, licenses
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button onClick={navigateToProducts}>Manage &nbsp; <ViewInArIcon /></Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                {isAuthorizedByFunction(me, 'access-customers') && (
                  <Grid item xs={4}>
                    <SearchDeployment />
                  </Grid>
                )}
              </Grid>

            </Fragment>
          )}
      </div>
    </div>
  );

}

export default Home
