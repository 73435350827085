import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom';

import config from './config'
import { themeOptions } from './theme';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import App from './components/App'

const theme = createTheme(themeOptions);

const BaseThemeWrapper = () => {
    return (<ThemeProvider theme={theme}><App /></ThemeProvider>)
};

ReactDOM.render(
    <Router basename={config.app.basename}>
        <BaseThemeWrapper />
    </Router>
    , document.getElementById('root'))
