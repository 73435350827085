import React, { useState, useCallback, Suspense, lazy, createContext, useEffect, useMemo, Fragment } from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import config from '../config';

import CorsErrorModal from './login/CorsErrorModal';
import AuthRequiredModal from './login/AuthRequiredModal';

// Components
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';

// Third Party Components
import {
  Box, Toolbar,
  Paper, Grid, LinearProgress
} from '@mui/material';

// Route Components
import Home from './home/Home';
import OktaLoginComponent from './login/Login';
const Customers = lazy(() => import('./customer/Customers'));
const CustomerDetails = lazy(() => import('./customer/CustomerDetails'));
const Products = lazy(() => import('./product/Products'));
const ProductDetails = lazy(() => import('./product/ProductDetails'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const UserGuide = lazy(() => import('./guide/UserGuide'));
const Changelog = lazy(() => import('./changelog/Changelog'));

const Users = lazy(() => import('./user/Users'));
const Roles = lazy(() => import('./role/Roles'));
const Audit = lazy(() => import('./audit/AdminAudit'));
const Unauthorized = lazy(() => import('./Unauthorized'));

const oktaAuth = new OktaAuth(config.okta);

// Context
import PermissionsContext from 'context/PermissionsContext';
import PermissionsContextModel from 'context/PermissionsContextModel';

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);
  const [permissionsAndUser, setPermissionsAndUser] = useState(PermissionsContextModel);
  const history = useHistory();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    }, []
  );

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  const onAuthResume = async () => {
    history.push('/login');
  };

  const LazyLoadedRoutesFallback = () => {
    return (
      <Box sx={{ my: 1, mx: 1 }}>
        <LinearProgress color="secondary" />
      </Box>
    )
  };
  
  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <PermissionsContext.Provider value={permissionsAndUser}>
        <Box sx={{ display: 'flex' }}>
          <Header setCorsErrorModalOpen={setCorsErrorModalOpen} permissionsAndUserValue={permissionsAndUser} setPermissionsAndUserState={setPermissionsAndUser} />
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar variant="dense" sx={{ minHeight: '2.5rem' }} />
            <Paper elevation={4} square sx={{ width: '100%', minHeight: `calc(100vh - 5.5em)` }}>
              <Grid container>
                <Grid item xs={12} sx={{ margin: '.75em' }}>
                  <Suspense fallback={LazyLoadedRoutesFallback()}>
                    <Switch>
                      <Route path="/" exact component={() => (<Home userInfo={permissionsAndUser.oktaUserInformation} />)} />
                      <Route path="/login/callback" render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />} />
                      <Route path="/login" render={() => <OktaLoginComponent {...{ setCorsErrorModalOpen }} />} />
                      <SecureRoute exact path="/customers" component={Customers} />
                      <SecureRoute exact path="/customers/:id" component={CustomerDetails} />
                      <SecureRoute exact path="/solutions" component={Products} />
                      <SecureRoute exact path="/solutions/:id" component={ProductDetails} />
                      <SecureRoute exact path="/guide" component={UserGuide} />
                      <SecureRoute exact path="/changelog" component={Changelog} />
                      <SecureRoute exact path="/roles" component={Roles} />
                      <SecureRoute exact path="/users" component={Users} />
                      <SecureRoute exact path="/audit" component={Audit} />
                      <Route exact path="/unauthorized" component={Unauthorized} />
                      <Route path="/*" component={PageNotFound} />
                    </Switch>
                  </Suspense>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Box>
      </PermissionsContext.Provider>
    </Security>
  )
}

export default App
