
import { map } from "lodash";
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

// RFC 2822 Email Validation
const uuidRegex = new RegExp("^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$", "i");
const urlRegex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&\\/\\/=]*)", "i");
const rfcEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

// Standardized Date Format
const standard = 'MMM Do YYYY';
const standardWithTime = 'MMM Do YYYY, h:mm:ss a';

export const validateEmail = (value) => {
    return rfcEmail.test(value.toLowerCase());
};

export const validateUUID = (value) => {
    return uuidRegex.test(value);
};

export const validateURL = (value) => {
    return urlRegex.test(value);
}

export const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
}

export const generateSolutionName = (value) => {
    if (value) {
        return value.toLowerCase().replaceAll(' ', '-');
    }
    return value;
};

export const filterEmptyObjects = (arr) => {
    return arr.filter(f => !isEmpty(f));
};

export const momentDateCompare = (v1, v2, withTime = false) => {
    const v1m = moment(v1, withTime ? standardWithTime : standard);
    const v2m = moment(v2, withTime ? standardWithTime : standard);
    if (v1m > v2m) {
        return 1;
    } else if (v1m < v2m) {
        return -1;
    } else {
        return 0;
    }
};

export const mapLicenseInfo = (data, products) => {
    if (data && products) {
        return filterEmptyObjects(data).map((value) => {
            const product = products.find(v => v.productId === value.productId);
            value.productName = !product ? '' : product.productName;
            return value;
        });
    }
    return [];
};

export const mapSubscriptionInfo = (data, products, licenses, organizations) => {
    if (data && products && licenses) {
        return filterEmptyObjects(data).map((value) => {
            const product = products.find(v => v.productId === value.productId);
            value.productName = !product ? '' : product.productName;

            const license = licenses.find(v => v.licenseId === value.licenseId);
            value.licenseName = !license ? '' : license.licenseName;

            const org = organizations.find(v => v.organizationId === value.organizationId);
            value.organizationName = !org ? '' : org.organizationName;

            // const startdate = value.startDate.indexOf('Z') > -1 
            //     ? moment(value.startDate).format(standard)
            //     : moment(value.startDate, standard).format(standard);
            // const enddate = value.endDate.indexOf('Z') > -1
            //     ? moment(value.endDate).format(standard)
            //     : moment(value.endDate, standard).format(standard);

            // value.startDateDisplay = startdate;
            // value.endDateDisplay = enddate;

            return value;
        });
    }
    return [];
};

export const mapRoleInfo = (data) => {
    if (data) {
        return filterEmptyObjects(data).map((value) => {
            const lastUpdatedDate = value.lastUpdated.indexOf('Z') > -1
                ? moment(value.lastUpdated).format(standardWithTime)
                : moment(value.lastUpdated, standardWithTime).format(standardWithTime);
            value.lastUpdatedDisplay = lastUpdatedDate;
            return value;
        });
    }
    return [];
};

export const mapAuditInfo = (data, ...args) => {
    if (data && args) {
        return filterEmptyObjects(data).map((value) => {
            // entityName is now an object created on the backend.
            if (value.entityName === '') {
                let entity = '';
                args.some(v => {
                    const testCus = v.find(f => f.customerId === value.entityId);
                    if (testCus) { entity = testCus.customerName; return true; }

                    const testSub = v.find(f => f.subscriptionId === value.entityId);
                    if (testSub) { entity = 'Subscription'; return true; }

                    const testOrg = v.find(f => f.organizationId === value.entityId);
                    if (testOrg) { entity = testOrg.organizationName; return true; }

                    const testLic = v.find(f => f.licenseId === value.entityId);
                    if (testLic) { entity = testLic.licenseName; return true; }

                    const testProd = v.find(f => f.productId === value.entityId);
                    if (testProd) { entity = testProd.productName; return true; }

                    return false;
                });
                value.entityName = entity;

                if (value.entityName === '' && value.action.toLowerCase().indexOf('delete') > -1) {
                    value.entityName = 'Deleted'
                }

                if (value.entityName === '') {
                    value.entityName = 'Unknown'
                }
            }

            const createddate = moment(value.date).format(standardWithTime);
            value.date = createddate;

            return value;
        });
    }
    return [];
};

export const mapUsersInfo = (data, ...args) => {
    if (data && args) {
        return filterEmptyObjects(data).map((value) => {
            let solutionsResolved = [];

            if (value.ndaSolutions) {
                value.ndaSolutions.forEach(s => {
                    let solName = '';
                    args.some(v => {
                        const testProd = v.find(f => f.productId === s);
                        if (testProd) { solName = testProd.productName; return true; }

                        return false;
                    });
                    if (solName !== '') {
                        solutionsResolved = [...solutionsResolved, solName];
                    } else {
                        solutionsResolved = [...solutionsResolved, s];
                    }
                });
            }

            value.ndaSolutionsDisplay = [...solutionsResolved];
            return value;
        });
    }
    return [];
}

export const keyify = (obj = {}, prefix = '', skipFn = false) => {
    if (skipFn) {
        return [];
    }
    return Object.keys(obj)
        .reduce(
            (res, el) => {
                if (prefix !== '' && res.indexOf(prefix.substring(0, prefix.length - 1)) < 0) {
                    res.push(prefix.substring(0, prefix.length - 1));
                }
                if (typeof obj[el] === 'object' && obj[el] !== null) {
                    // test to see if it has keys before moving
                    if (Object.keys(obj[el]).length === 0) {
                        return [...res, prefix + el];
                    } else {
                        return [...res, ...keyify(obj[el], prefix + el + '.')];
                    }
                }
                return [...res, prefix + el];
            }, []
        );
}
    

export const mapValuesToOptions = (shortValue, labelValue, idValue = uuidv4()) => {
    return { short: shortValue, label: labelValue, id: idValue };
};

export const isAuthorizedByPermission = (user, permissions) => {
    if (user && permissions) {
        const usersOwnPermissions = [...user.permissions];
        return permissions.some(r => usersOwnPermissions.includes(r));
    }
    return false;
};

export const isAuthorizedByFunction = (user, func) => {
    if (user && func) {
        let permissionsList = ['csm-admin'];
        switch (func) {
            case 'access-customers':
                permissionsList = [...permissionsList, 'csm-read-customers', 'csm-edit-customers'];
                break;
            case 'access-edit-customers':
                permissionsList = [...permissionsList, 'csm-edit-customers'];
                break;
            case 'access-customer-deployments':
                permissionsList = [...permissionsList, 'csm-read-customer-deployments', 'csm-edit-customer-deployments'];
                break;
            case 'access-edit-customer-deployments':
                permissionsList = [...permissionsList, 'csm-edit-customer-deployments'];
                break;
            case 'access-customer-deployments-properties':
                permissionsList = [...permissionsList, 'csm-read-solution-properties', 'csm-edit-solution-properties'];
                break;
            case 'access-edit-customer-deployments-properties':
                permissionsList = [...permissionsList, 'csm-edit-solution-properties'];
                break;
            case 'access-customer-subscriptions':
                permissionsList = [...permissionsList, 'csm-read-customer-subscriptions', 'csm-edit-customer-subscriptions'];
                break;
            case 'access-edit-customer-subscriptions':
                permissionsList = [...permissionsList, 'csm-edit-customer-subscriptions'];
                break;
            case 'access-customer-products':
                permissionsList = [...permissionsList, 'csm-read-customer-subscriptions', 'csm-edit-customer-subscriptions', 'csm-read-customer-deployments', 'csm-edit-customer-deployments'];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-customer-licenses':
                permissionsList = [...permissionsList, 'csm-read-customer-subscriptions', 'csm-edit-customer-subscriptions'];
                break;
            case 'access-customer-auditing':
                permissionsList = [...permissionsList];
                break;
            case 'access-solutions':
                permissionsList = [...permissionsList, 'csm-read-solutions', 'csm-edit-solutions'];
                break;
            case 'access-edit-solutions':
                permissionsList = [...permissionsList, 'csm-edit-solutions'];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-solution-properties':
                permissionsList = [...permissionsList, 'csm-read-solution-properties', 'csm-edit-solution-properties'];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-edit-solution-properties':
                permissionsList = [...permissionsList, 'csm-edit-solution-properties'];
                break;
            case 'access-solution-licenses':
                permissionsList = [...permissionsList, 'csm-read-solution-licenses', 'csm-edit-solution-licenses'];
                break;
            case 'access-edit-solution-licenses':
                permissionsList = [...permissionsList, 'csm-edit-solution-licenses'];
                break;
            case 'access-solution-customers':
                permissionsList = [...permissionsList, 'csm-read-solutions', 'csm-edit-solutions', 'csm-read-solution-properties', 'csm-edit-solution-properties', 'csm-read-solution-licenses', 'csm-edit-solution-licenses'];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-solution-auditing':
                permissionsList = [...permissionsList];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-roles':
                permissionsList = [...permissionsList];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-users':
                permissionsList = [...permissionsList];
                break;
            // deepcode ignore DuplicateCaseBody: Aware of issue, but keeping the case for a later potential change
            case 'access-admin-audit-log':
                permissionsList = [...permissionsList];
                break;
            case 'access-delete':
                permissionsList = [...permissionsList, 'csm-delete'];
                break;
            default:
                break;
        }
        //console.log('User %o', [...user.permissions]);
        //console.log('Requested: %o', [...permissionsList]);
        return permissionsList.some(r => [...user.permissions].includes(r));
    }
    return false;
};

export const isAuthorizedByFunctions = (user, funcs) => {
    if (user && funcs) {
        let results = [];
        funcs.forEach(func => {
            results = [...results, isAuthorizedByFunction(user, func)];
        });
        return results.includes(true);
    }
    return false;
};

export default {
    validateEmail,
    validateUUID,
    validateURL,
    copyToClipboard,
    generateSolutionName,
    momentDateCompare,
    mapSubscriptionInfo,
    mapRoleInfo,
    mapLicenseInfo,
    mapAuditInfo,
    mapUsersInfo,
    mapValuesToOptions,
    keyify,
    isAuthorizedByPermission,
    isAuthorizedByFunction,
    isAuthorizedByFunctions
}