import { fetchWithRetries, baseUri } from './fetchWrap';

/**
 * @description Gets the current user.
 * @param {*} token okta
 * @return {Promise} 
 */
export const getMe = (token, urlOverride = false, env = '') => {
    const url = urlOverride ? `${env}/users/me` : `${baseUri()}/users/me`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token);
};

/**
 * @description Gets all of the current users that have assigned permissions and roles; nda solutions.
 * @param {*} token okta
 * @return {Promise} 
 */
export const getUsers = (token) => {
    const url = `${baseUri()}/users`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token);
};

/**
 * @description Adds a new user.
 * @param {*} fields email, ISOCountryCode, ndaSolutions, permissions, roles
 * @param {*} token okta
 * @return {*} new user
 */
export const addUser = (fields, token) => {
    const url = `${baseUri()}/users`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

/**
 * @description Updates the user with the provided values.
 * @param {*} id userId
 * @param {*} fields email, ISOCountryCode, ndaSolutions, permissions, roles
 * @param {*} token okta
 * @return {*} updated user details
 */
export const updateUser = (id, fields, token) => {
    const url = `${baseUri()}/users/${id}`;
    return fetchWithRetries(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

export default {
    getMe,
    getUsers,
    addUser,
    updateUser
}