import React, { Fragment } from 'react';
import {
    Dialog, Button, DialogTitle,
    DialogContent, DialogContentText, Divider,
    DialogActions
} from '@mui/material'

const AuthRequiredModal = ({ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }) => {
    const closeModal = () => {
        setAuthRequiredModalOpen(false);
    };

    const confirmModal = () => {
        setAuthRequiredModalOpen(false);
        triggerLogin();
    };

    return (
      <Fragment>
            <Dialog fullWidth maxWidth={'sm'} open={authRequiredModalOpen} onClose={closeModal} onBackdropClick={() => false}>
                <DialogTitle sx={{ pb: 0 }}>Reauthentication</DialogTitle>
                <DialogContent>
                    <DialogContentText variant='body2'>
                        Your session has expired. <br></br> Do you want to re-authenticate again?
                    </DialogContentText>
                    <Divider />
                    <DialogActions>
                      <Button onClick={closeModal} color='primary'>No</Button>
                      <Button onClick={confirmModal} color='secondary'>Yes</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
      </Fragment>
    );
};

export default AuthRequiredModal;
