import { useOktaAuth } from '@okta/okta-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, Link as RouterLink, Redirect } from 'react-router-dom';

import { 
    AppBar, CssBaseline, Toolbar, Typography, Button, Link, Tooltip
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ContactMailIcon from '@mui/icons-material/ContactMail';

// Backend
import { getApplicationEnvs, getApplicationStart } from 'services/startupBackend';

// Utility
import { clearAllApplicationStorageSafe, getStorageItem, setStorageItem } from 'services/applicationStorage';
import { mapValuesToOptions } from 'components/shared/utilities';

// Context
import PermissionsContextModel from 'context/PermissionsContextModel';

// Config
import config from '../../config';

const Header = ({ setCorsErrorModalOpen, permissionsAndUserValue, setPermissionsAndUserState }) => {
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (!authState || (authState && !authState.isAuthenticated)) {
            // When user isn't authenticated, forget any user info
            setPermissionsAndUserState({
                ...permissionsAndUserValue,
                ...PermissionsContextModel
            });
        } else {
            oktaAuth.getUser().then(async (info) => {
                const token = oktaAuth.getAccessToken();
                
                let availableEnvs = [];
                // env user checks
                if (config.env === 'Sandbox') {
                    const [sandboxFetch] = await getApplicationEnvs(token);
                    const sandboxStatus = sandboxFetch.status === 'fulfilled';
                    availableEnvs = sandboxStatus ? [...availableEnvs, 'Sandbox'] : [...availableEnvs];
                } else {
                    const [developmentFetch, testFetch, productionFetch] = await getApplicationEnvs(token);
                    const devStatus = developmentFetch.status === 'fulfilled';
                    availableEnvs = devStatus ? [...availableEnvs, 'Development'] : [ ...availableEnvs];
                    const testStatus = testFetch.status === 'fulfilled';
                    availableEnvs = testStatus ? [...availableEnvs, 'Test'] : [...availableEnvs];
                    const prodStatus = productionFetch.status === 'fulfilled';
                    availableEnvs = prodStatus ? [...availableEnvs, 'Production'] : [...availableEnvs];
                }

                if (availableEnvs.length === 0) {
                    // idk what to do, doesn't have access to anything, we need to goto unauthorized or user not found?
                    setPermissionsAndUserState({
                        ...permissionsAndUserValue,
                        me: {
                            permissions: []
                        },
                        contextLoaded: true,
                        oktaUserInformation: info,
                        noUser: true
                    });
                } else {
                    // keep the users preference if previously loading the app, otherwise choose their default based on Development -> Test -> Production
                    const key = getStorageItem('csm-env', true);
                    if (key !== null && key !== undefined && key.value.length > 0 && availableEnvs.includes(key.value)) {
                        // don't set the storage key as it's good option, or the user is refreshing the page
                    } else {
                        setStorageItem('csm-env', { value: availableEnvs[0] }, true);
                    }

                    const [userFetch, configFetch, roleFetch] = await getApplicationStart(token);
                    const { data: permissionsData } = userFetch.value;
                    const { data: configurationData } = configFetch.value
                    const { data: roleData } = roleFetch.value;
                    const baseRolesToSearch = roleData.filter(f => permissionsData.roles.includes(f.id)).map(m => m.permissions);
                    const finalPermissions = [...new Set([...permissionsData.permissions, ...[].concat(...baseRolesToSearch)])];
                    setPermissionsAndUserState({
                        ...permissionsAndUserValue,
                        permissions: [...configurationData.permissions],
                        permissionOptions: [...configurationData.permissions].map((v) => mapValuesToOptions('', v.id)),
                        roles: [...roleData],
                        me: {
                            ...permissionsData,
                            permissions: [...finalPermissions]
                        },
                        envsAvailable: [...availableEnvs],
                        contextLoaded: true,
                        oktaUserInformation: info
                    });
                }
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);

    const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');

    const logout = async () => {
        const basename = window.location.origin + history.createHref({ pathname: '/' });
        try {
            await oktaAuth.signOut({ postLogoutRedirectUri: basename });
        } catch (err) {
            if (isCorsError(err)) {
                setCorsErrorModalOpen(true);
            } else {
                throw err;
            }
        } finally {
            history.push('/login');
        }
    };

    const clearPreferences = () => {
        clearAllApplicationStorageSafe();
        return;
    };

    const mailTo = (e) => {
        if (window) {
            window.location.href = "mailto:PSAWSRequests@genesys.com?subject=[CSM] Help Request&body=See CSM user guide for more details regarding what to include in the body of this email.";
            e.preventDefault();
        }
        return;
    }

    if (!authState) {
        return null;
    }

    return (
        <Fragment>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar variant="dense">
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        <Link component={RouterLink} to='/' underline="none" color="inherit">Customer Subscription Manager</Link>
                    </Typography>
                    {authState.isAuthenticated && (
                        <Fragment>
                            <Button color="inherit" onClick={logout}>Logout&nbsp;<LogoutIcon fontSize='small' /></Button>
                            <Button color="inherit" onClick={mailTo}>Help&nbsp;<ContactMailIcon fontSize='small' /></Button>
                            <Tooltip title="Clear Application Storage (IE: Table Preferences)">
                                <Button color="inherit" onClick={clearPreferences}><ClearAllIcon fontSize='small' /></Button>
                            </Tooltip>
                        </Fragment>
                        
                    )}
                </Toolbar>
            </AppBar>
            {!authState.isAuthenticated && (<Redirect to={'/login'}/>)}
        </Fragment>
    );

};

export default Header;
