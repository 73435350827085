import { baseUri, fetchWithRetries } from './fetchWrap';

/**
 * @description Gets the current configuration for the application.
 * @param {*} token okta
 * @return {Promise} 
 */
export const getConfiguration = (token) => {
    const url = `${baseUri()}/config`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token);
};

export default {
    getConfiguration
}