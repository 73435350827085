import config from '../config';

import { getConfiguration } from './configBackend';
import { fetchAllWithRetries } from './fetchWrap';
import { getRoles } from './roleBackend';
import { getMe } from './userBackend';

/**
 * @description Gets the required fetches for all information needed at the start of the application.
 * @param {*} token okta
 * @return {Promise} 
 */
export const getApplicationStart = (token) => {
    const fetchs = [
        getMe(token),
        getConfiguration(token),
        getRoles(token)
    ];
    return fetchAllWithRetries(fetchs);
};

export const getApplicationEnvs = (token) => {
    let fetchs = [];

    if (config.env === 'Sandbox') {
        fetchs = [ ...fetchs,
            getMe(token, true, config.server.sandbox.baseUri)
        ];
    } else if (config.env === 'Production') {
        fetchs = [ ...fetchs,
            getMe(token, true, config.server.dev.baseUri),
            getMe(token, true, config.server.test.baseUri),
            getMe(token, true, config.server.prod.baseUri)
        ];
    }
    
    return fetchAllWithRetries(fetchs);
}



export default {
    getApplicationStart,
    getApplicationEnvs
}