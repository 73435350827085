
const location = window.location;
const url = `${location.protocol}//${location.hostname}`;

// This is the configuration is for sandbox, dev, test deployments
const DevOKTAConfiguration = {
  okta: {
    issuer: 'https://dev-00014457.okta.com/oauth2/default',
    clientId: '0oa3ptrtqbgTakoCg5d7',
    redirectUri: `${url}:3000/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}:3000/`
  }
};

const TestOKTAConfiguration = {
  okta: {
    issuer: 'https://dev-00014457.okta.com/oauth2/default',
    clientId: '0oa3ptrtqbgTakoCg5d7',
    redirectUri: `${url}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}/`
  }
};

// This is the configuration for production provided by IT
const ProdOKTAConfiguration = {
  okta: {
    issuer: 'https://genesys.okta.com/oauth2/default',
    clientId: '0oa1o2y02opupVTij0h8',
    redirectUri: `${url}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}/`
  }
};

// This is where common configuration is shared across all instances.
const CommonConfiguration = {
  app: {
    basename: '',
    storageVersion: 'v1'
  }
};

// This is sandbox and local configuration.
const local = {
  ...DevOKTAConfiguration,
  ...CommonConfiguration,
  serverDefault: 'Sandbox',
  serverOptions: ["Sandbox"],
  server: {
    sandbox: {
      baseUri: 'https://api.customer-subscription-manager.genesyspsdevops-dev.com/sandbox'
    }
  },
  env: 'Sandbox'
}

// Development and Test configs are used for deployments to AWS. to Verify and manage.
const development = {
  ...local,
  ...TestOKTAConfiguration,
  serverDefault: 'Sandbox',
  serverOptions: ["Sandbox"],
  server: {
    sandbox: {
      baseUri: 'https://api.customer-subscription-manager.genesyspsdevops-dev.com/sandbox'
    }
  },
  env: 'Sandbox'
};

const production = {
  ...ProdOKTAConfiguration,
  ...CommonConfiguration,
  serverDefault: 'Development',
  serverOptions: ["Development", "Test", "Production"],
  server: {
    dev: {
      baseUri: 'https://api.customer-subscription-manager.genesyspsdevops.com/dev'
    },
    test: {
      baseUri: 'https://api.customer-subscription-manager.genesyspsdevops.com/test'
    },
    prod: {
      baseUri: 'https://api.customer-subscription-manager.genesyspsdevops.com/prod'
    }
  },
  env: 'Production'
}


const env = process.env.REACT_APP_CUSTOM_ENV.trim();
console.log('Application Env: %o', env);
console.log('Application Version: %o', env === 'local' ? 'sandbox build' : process.env.REACT_APP_BUILD_VERSION);
console.log('Having issues? Please reach out to PSAWSRequests@genesys.com');
let config
switch (env) {
  case 'local':
    config = local;
    break;
  case 'sandbox':
    config = development;
    break;
  case 'production':
    config = production;
    break;
  default:
    break;
}

export default config
export const headerTitle = 'Customer Subscription Manager - CSM'
