import React, { Fragment } from 'react';

// Components Material UI
import {
    Typography, Grid, TextField
} from '@mui/material';

const CTextField = ({ title, id, formFields, fieldName, handler, preline = false, disabled = false}) => {
    return (
        <Fragment>
            <Grid item xs={12} md={12}>
                <Typography component="label" variant='body2' sx={{whiteSpace: preline ? 'pre-line' : 'normal'}}>
                    { title === '' ? '' : `${title}:` }
                </Typography>
                <TextField
                    id={ id }
                    variant="outlined"
                    fullWidth
                    hiddenLabel
                    size="small"
                    value={ formFields[`${fieldName}`] }
                    error={ formFields[`${fieldName}Error`] }
                    helperText={ formFields[`${fieldName}Error`] ? formFields[`${fieldName}ErrorMsg`] : formFields[`${fieldName}HelpText`] }
                    onChange={handler}
                    disabled={disabled}
                    autoComplete='off'
                />
            </Grid>
        </Fragment>
    )
};

export default CTextField;