import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import {
    Typography, Box, Button,
    LinearProgress
} from '@mui/material';

import CTextField from './form/CTextField';

import { getOrganization } from 'services/organizationBackend';

import { validateUUID } from './utilities';

const defaultSearchState = {
    search: '',
    searchError: false,
    searchErrorMsg: '',
    searchHelpText: ''
};

const SearchDeployment = ({}) => {
    const isMounted = useRef(true);
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();


    const [errorSummary, setErrorSummary] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchState, setSearchState] = useState(defaultSearchState);


    useEffect(
        () => {
            return () => {
                isMounted.current = false;
            }
        },
        []
    );

    const handleChangeSearchField = (event) => {
        setErrorSummary('');
        if (!event.target.value || event.target.value.length === 0) {
            setSearchState({ ...searchState, searchError: true, searchErrorMsg: '', searchHelpText: '', search: event.target.value });
            return;
        }
        if (!validateUUID(event.target.value)) {
            setSearchState({ ...searchState, searchError: true, searchErrorMsg: 'Must be a valid UUID', searchHelpText: '', search: event.target.value });
            return;
        }
        setSearchState({ ...searchState, searchError: false, searchErrorMsg: '', searchHelpText: '', search: event.target.value });
        return;
    };

    const fetchDeployment = async (token) => {
        if (isMounted.current) {
            setLoading(true);
            try {
                const { data, success, status } = await getOrganization(searchState.search, token);
                setLoading(false);
                if (data && data.details) {
                    const customerId = data.details.customerId;
                    history.push(`/customers/${customerId}`);
                }
            } catch ({ data, success, status }) {
                setLoading(false);
                setErrorSummary(`${data.message}`);
            }
        }
    }

    const handleSearch = async (event) => {
        const token = oktaAuth.getAccessToken();
        await fetchDeployment(token);
    };

    return (
        <Fragment>
            <Box sx={{ my: 1, mx: 1 }}>
                <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                    Search Deployment
                </Typography>
                <Typography color="text.secondary" variant="body2">
                    Enter an Genesys Cloud Organization ID; Multicloud CX ID
                </Typography>
                {errorSummary && errorSummary !== '' && (
                    <Typography component="div" variant='body2' color='error'>
                        {errorSummary}
                    </Typography>
                )}
                <Box sx={{ my: 2 }}>
                    <CTextField
                        title={''} id={'serach-deployment-id-field'}
                        formFields={searchState} fieldName={'search'}
                        handler={handleChangeSearchField}
                    />
                    {!loading && (
                        <Button size="small" variant="contained" color='success' onClick={handleSearch} sx={{ mt: 1 }}>Search</Button>
                    )}
                </Box>
                {loading && (
                    <Box sx={{ my: 1, mx: 1 }}>
                        <LinearProgress color="secondary" />
                    </Box>
                )}
            </Box>
        </Fragment>
    )
};


export default SearchDeployment;