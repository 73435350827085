const PermissionsContextModel = {
    permissions: [],
    permissionOptions: [],
    roles: [],
    me: {},
    oktaUserInformation: {},
    envsAvailable: [],
    contextLoaded: false,
    noUser: false
};

export default PermissionsContextModel;