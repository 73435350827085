import React, { Fragment, useState, useEffect, useRef, useContext  } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import {
    Box, Drawer, Toolbar, List, 
    ListItemIcon, ListItemText, ListSubheader,
    FormControl, Select, MenuItem, ListItem, Typography, Divider
} from '@mui/material';

import './Sidebar.css';

import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import QuizIcon from '@mui/icons-material/Quiz';
import SecurityIcon from '@mui/icons-material/Security';
import GroupsIcon from '@mui/icons-material/Groups';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DifferenceIcon from '@mui/icons-material/Difference';

import config from '../../config';

import { getStorageItem, setStorageItem } from 'services/applicationStorage';
import PermissionsContext from 'context/PermissionsContext';
import { isAuthorizedByFunction, isAuthorizedByFunctions, isAuthorizedByPermission } from 'components/shared/utilities';

const drawerWidth = 240;

const Sidebar = () => {
    const isMounted = useRef(true);
    const history = useHistory();
    const location = useLocation();
    const { authState } = useOktaAuth();
    const { me, contextLoaded, envsAvailable, noUser } = useContext(PermissionsContext);

    const [env, setEnv] = useState({ value: '' });

    useEffect(() => {
        if (contextLoaded) {
            const key = getStorageItem('csm-env', true);
            if (key !== null && key !== undefined && key.value.length > 0) {
                setEnv(key);
            } else {
                const env = { value: config.serverDefault };
                setEnv(env);
                setStorageItem('csm-env', env, true);
            }
        }

        return () => {
            isMounted.current = false;
        }
    }, [contextLoaded])

    const handleEnvChange = (event) => {
        setEnv({ value: event.target.value });
        setStorageItem('csm-env', { value: event.target.value }, true);
        history.push('/');
        history.go(0);
    };

    const getAppVersion = () => {
        return process.env.REACT_APP_BUILD_VERSION;
    }

    if (!authState) {
        return null;
    }

    return (
        <Fragment>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List component={'nav'} className='csm-sidebar' subheader={
                        <ListSubheader component="div" id="management-subheader">
                            Getting Started
                        </ListSubheader>
                    } sx={{ mb: 2 }}>
                        {authState.isAuthenticated && contextLoaded && !noUser && (
                            <Fragment>
                                <ListItem key='item-env'>
                                    <ListItemIcon>
                                        <StorageIcon />
                                    </ListItemIcon>
                                    <FormControl variant="standard" color='secondary' sx={{ m: 1, minWidth: 120 }}>
                                        <Select
                                            id="env-select"
                                            value={env.value}
                                            onChange={handleEnvChange}
                                            autoWidth

                                        >
                                            {envsAvailable.map((text) => (
                                                <MenuItem key={text} value={text}>{text}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                {isAuthorizedByFunction(me, 'access-customers') && (
                                    <ListItem component={Link} to={`/${'Customers'.toLowerCase()}`} key={'Customers'} selected={location.pathname.includes('customer')}>
                                        <ListItemIcon>
                                            <GroupIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Customers'} />
                                    </ListItem>
                                )}
                                {isAuthorizedByFunction(me, 'access-solutions') && (
                                    <ListItem component={Link} to={`/${'Solutions'.toLowerCase()}`} key={'Solutions'} selected={location.pathname.includes('solution')}>
                                        <ListItemIcon>
                                            <ViewInArIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Solutions'} />
                                    </ListItem>
                                )}
                            </Fragment>
                        )}
                        <ListItem component={Link} to={`/guide`} key={'Guide'} selected={location.pathname.includes('guide')}>
                            <ListItemIcon>
                                <QuizIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Guide'} />
                        </ListItem>
                        <ListItem component={Link} to={`/changelog`} key={'Changelog'} selected={location.pathname.includes('changelog')}>
                            <ListItemIcon>
                                <DifferenceIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Changelog'} />
                        </ListItem>
                    </List>
                    {authState.isAuthenticated && contextLoaded && !noUser && (
                        <Fragment>
                            {isAuthorizedByFunctions(me, ['access-roles', 'access-users', 'access-admin-audit-log']) && (
                                <Fragment>
                                    <Divider />
                                    <List component={'nav'} className='csm-sidebar' subheader={
                                        <ListSubheader component="div" id="admin-subheader">
                                            Administration
                                        </ListSubheader>
                                    } sx={{ mt: 2 }}>
                                        {isAuthorizedByFunction(me, ['access-roles']) && (
                                            <ListItem component={Link} to={`/${'Roles'.toLowerCase()}`} key={'Roles'} selected={location.pathname.includes('role')}>
                                                <ListItemIcon>
                                                    <GroupsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Roles'} />
                                            </ListItem>
                                        )}
                                        {isAuthorizedByFunction(me, ['access-users']) && (
                                            <ListItem component={Link} to={`/${'Users'.toLowerCase()}`} key={'Users'} selected={location.pathname.includes('user')}>
                                                <ListItemIcon>
                                                    <SecurityIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Users'} />
                                            </ListItem>
                                        )}
                                        {isAuthorizedByFunction(me, ['access-admin-audit-log']) && (
                                            <ListItem component={Link} to={`/${'Audit'.toLowerCase()}`} key={'Audit'} selected={location.pathname.includes('audit')}>
                                                <ListItemIcon>
                                                    <VerifiedUserIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Audit Log'} />
                                            </ListItem>
                                        )}
                                    </List>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Box>
                <Box sx={{ mt: 'auto', pb: 1, mx: 'auto' }}>
                    <Typography variant="body2" color={'primary'} textAlign="center">
                        Version: {getAppVersion()}
                        <br />
                        &copy; 2022 Genesys
                    </Typography>
                </Box>
            </Drawer>
        </Fragment>
    )
};

export default Sidebar;
