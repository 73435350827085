import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import logo from './GEN_id_black_rgb.jpg'
import { Redirect } from 'react-router-dom';

import config from '../../config';

const Login = ({ setCorsErrorModalOpen }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode } = config.okta;
    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: logo,
      i18n: {
        en: {
          'primaryauth.title': 'Genesys - Customer Subscription Manager',
        },
      },
      authParams: {
        issuer,
        scopes,
      },
      useInteractionCodeFlow: useInteractionCode || false,
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      },
    );
    
    const isCorsError = (err) => (err.name === 'AuthApiError' && !err.statusCode);

    widget.on('afterError', (_context, error) => {
      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    return () => widget.remove();
  }, [oktaAuth, setCorsErrorModalOpen]);

  if (authState && authState.isAuthenticated) {
    return (
      <Redirect to='/' />
    );
  }

  return (
    <div>
      <div ref={widgetRef} />
    </div>
  );
};

export default Login;
