import config from '../config';

export const setStorageItem = (key, value, noVersion = false) => {
    if (key !== null && key !== undefined) {
        const json = JSON.stringify(value);
        const trueKey = !noVersion ? `${key}-${config.app.storageVersion}` : `${key}`;
        localStorage.setItem(trueKey, json);
    }
};

export const getStorageItem = (key, noVersion = false) => {
    if (key !== null && key !== undefined) {
        const trueKey = !noVersion ? `${key}-${config.app.storageVersion}` : `${key}`;
        const json = localStorage.getItem(trueKey);
        return JSON.parse(json);
    }
    return null;
};

export const removeStorageItem = (key, noVersion = false) => {
    if (key !== null && key !== undefined) {
        const trueKey = !noVersion ? `${key}-${config.app.storageVersion}` : `${key}`;
        localStorage.removeItem(trueKey);
    }
};

export const clearAllStorageItems = () => {
    if (localStorage.length > 0) {
        localStorage.clear();
    }
};

export const clearAllApplicationStorageSafe = () => {
    if (localStorage && localStorage.length > 0) {
        var keysToDelete = [];
        Object.entries(localStorage).forEach(f => {
            if (f[0] !== 'csm-env' && (f[0].includes(`-${config.app.storageVersion}` || f[0].includes('csm-')))) {
                keysToDelete.push(f[0]);
            }
        });
        keysToDelete.forEach(f => removeStorageItem(f, true));
    }
}

export default {
    setStorageItem,
    getStorageItem,
    removeStorageItem,
    clearAllStorageItems,
    clearAllApplicationStorageSafe
}