import { fetchWithRetries, baseUri } from './fetchWrap';

/**
 * @description Gets all of the current roles that from the db.
 * @param {*} token okta
 * @return {Promise} 
 */
export const getRoles = (token) => {
    const url = `${baseUri()}/roles`;
    return fetchWithRetries(url, {
        method: 'GET'
    }, token);
};

/**
 * @description Adds a new user.
 * @param {*} fields roleName (lowercase), permissions
 * @param {*} token okta
 * @return {*} new role
 */
export const addRole = (fields, token) => {
    const url = `${baseUri()}/roles`;
    return fetchWithRetries(url, {
        method: 'POST',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

/**
 * @description Updates the user with the provided values.
 * @param {*} id userId
 * @param {*} fields roleName (lowercase), permissions
 * @param {*} token okta
 * @return {*} updated role details
 */
export const updateRole = (id, fields, token) => {
    const url = `${baseUri()}/roles/${id}`;
    return fetchWithRetries(url, {
        method: 'PATCH',
        body: JSON.stringify({
            ...fields
        })
    }, token);
};

export default {
    getRoles,
    addRole,
    updateRole
}